.popover {
	outline:none;
	max-width: 100%;
	padding: 0;
	padding-bottom: 10px;
	h3.popover-title {
		background: $cyan;
		color: $white;
		@extend .font-medium;
		font-size: 12px;
	}
	.popover-content {
		@extend .font-regular;
		font-size: 12px;
	}
}

.popover-normal {
	outline:none;
	max-width: 12rem;
	padding: 0;
	/* padding-bottom: 10px; */
	h3.popover-title {
		background: $cyan;
		color: $white;
		@extend .font-medium;
		font-size: 12px;
	}
	.popover-content {
		@extend .font-regular;
		font-size: 12px;
	}
}

.am-popover {
	opacity: 1;
	transform: translateX(0px);
	/*transition: all .15s ease-in;*/
	&.ng-enter {
		opacity: 0;
		/*transform: translateX(-30px);*/
		
		&.ng-enter-active {

		}
	}
	&.ng-leave {
		opacity: 1;
		&.ng-leave-active {
			opacity: 0;
		}
	}
}

.wider-popover {
	max-width: 24rem;
	opacity: 1;
	transform: translateX(12rem);
	/*transition: all .15s ease-in;*/
	&.ng-enter {
		opacity: 0;
		/*transform: translateX(-30px);*/
		
		&.ng-enter-active {

		}
	}
	&.ng-leave {
		opacity: 1;
		&.ng-leave-active {
			opacity: 0;
		}
	}
}
