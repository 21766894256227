main {
    min-height: 100vh;
    background-color: $pageBackGround;
    background-image: url('../img/app_segment.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 90vw 300px;
    padding: 0;
    margin: 0;
}

.nav-wrap-flex {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

.nav-logo-wrap {
    height: 120px;
    img {
        height: 100%;
    }
}

.nav-nav-wrap {
    align-self: flex-end;
    ul {
        display: flex;
        padding: 5px;
        margin: 0;
        list-style-type: none;
        background: $white;
        border-radius: 24px;
        letter-spacing: 1.2px;
        li {
            border-radius: 24px;
            padding: 2px 10px;
            font-size: 12px;
            a {
                color: $navy;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        li.active {
            background: linear-gradient(90deg, $purple, $navy);
            a {
                color: $white;
            }
        }
    }
}

.nav-user-wrap {
    align-self: flex-start;
    ul {
        letter-spacing: 1.1px;
        display: flex;
        padding: 5px;
        margin: 0;
        list-style-type: none;
        background: $white;
        border-radius: 24px;
        li {
            border-radius: 24px;
            padding: 0px 10px;
            a {
                color: $navy;
            }
        }
        li.active {
            background: linear-gradient(90deg, purple, $navy);
            a {
                color: $white;
            }
        }
    }
}

.btn-logout {
    height: 20px;
    padding: 0px 12px;
    font-size: 12px;
}

p.btn-logout {
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 2px;
    vertical-align: center;
}

.lock {
    height: 12px;
    position: relative;
    bottom:2px;
    margin-left: 10px;
}

.no-pad-left {
    padding-left: 0 !important;
}

.consult-timer-box {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    position:absolute;
    right: 1rem;
    /* right: 0; */
    /* right: 0; */
    /* padding-top: 1rem; */
    /* margin-right: 1rem; */
    /* margin-top: 3rem; */
}

.timer-box {
   border-width: 1px;
   border-color: rgba(128, 128, 128, 0.217);
   border-style:solid;
   border-radius: 4px;
   padding-left: 0.5rem;
   padding-right: 0.5rem;
   padding-top: 0.25rem;
   padding-bottom: 0.25rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 1rem;
   min-width: 10rem;
}