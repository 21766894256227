/* .op-header {
	color: $white;
	text-align: center;
	padding: 5px 0;
	margin-bottom: 10px;
    color: $navy !important;
	h4 {
		font-size: 1em;
	}
} */

.operation-form {
	@extend .font-regular;
	color: $textGrey;
	font-size: 12px;
	// background-color: pink;
	padding: 0 20px;
	border-top: 2px solid $navy;
	padding-top: 3em;

	select,
	.form-control {
		border-radius: 20px;
		box-shadow: none;
		@extend .font-regular;
		// color: $textGrey;
		color: $navy;
		font-size: 12px;
		border: 1px solid $navy;
		/* -webkit-appearance: none; */
		-webkit-appearance: radio;
    	appearance: radio;
    }
    
    .single-value-control {
        width: 40%;
        margin-left: 100px;
    }

	.form-group-label {
		padding-right: 20px;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 25px;

		.control-label {
			text-align: right;
		}
	}

	.flex {
		display: flex;
		padding: 0;
		position: relative;

		select,
		.input,
		.inline-number,
		.mark-wrap {
			margin-right: 20px;
			width: 100px;
		}
	}

	.mark-wrap,
	.mark-wrap-deg {
		position: relative;

		.six-pre {
			position: absolute;
			top: 20%;
			transform: translateY(-50%);
            /* left: -20px; */
            color: $navy !important;
		}

		.stripe-post {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -15px;
		}

		.stripe-pre {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -12px;
		}
	}

	.mark-wrap-deg {
		.deg {
			width: 80px;
			padding-left: 6px;
			padding-right: 6px;
		}
		
		.stripe-post {
			right: -37px;
		}
	}

	label {
		color: $navy !important;
		@extend .font-bold;
	}

	.marker {
		position: relative;
		top: 2px;
		@extend .font-regular;
	}
}

.btn-next {
	width: 120px;
	@extend .font-bold;
}

.check-label {
	position: relative;

	.check-span {
		position: relative;
		top: 2px;
	}
}

.lens {
	width: 220px;
}

.lens-label {
	margin-top: 34px;
}

.visual-label {
	margin-top: 70px;
}

.form-group-label-top {
	margin-top: 40px;
}

.col-bin {
	padding-left: 60px;
}

.pressure-wrap {
	display: flex;
	justify-content:flex-start;
	/* max-width: 460px; */

	.form-group {
		width: 40%;
	}
}

.half-width {
	max-width: 50%;
}

.quarter-width {
	max-width: 25%;
}

.blue-box {
	border: 1px solid $navy;
	padding: 15px;
	background-color: $paleBlue;
	margin-bottom: 15px;
	border-radius: 8px;
	.header {
		@extend .text-navy;

		.h4 {
			@extend .font-bold;
			margin-bottom: 5px;
			margin-top: 0;
		}
	}
}

.eye-heading {
	margin-bottom: 20px;
}

.operation-note-save-wrap {
	min-height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0;

	.progression-wrap {
		min-width: 250px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.save-progress {
			cursor: pointer;
			margin-right: 20px;
			@extend .text-navy;
		}
	}

	.progression-alert-wrap {
		width: calc(100% - 280px);

		.alert {
			margin-bottom: 0;

			& + .alert {
				margin-top: 10px;
			}
		}
	}
}

.stack-vert {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	top: 2px;
	margin-left: 10px;

	.radio-inline + .radio-inline {
		margin-left: 0;
		margin-top: 5px;
	}
}

.optional {
	@extend .text-purple;
	@extend .font-light-italic;
}

.flex-biometry {
	align-items: center;

	.control-label {
		margin-right: 15px;
		margin-bottom: 0;
	}
}

.biometry-input {
	width: 100px;
	margin-right: 20px;
}

.axis-wrap {
	position: relative;
}

.axis-times,
.axis-deg {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform: translateY(-50%);
}

.axis-times {
	right: calc(100% + 6px);
}

.axis-deg {
	left: 110px;
}

.section-label {
	margin-bottom: 10px;
}

.biometry {
	.form-row {
		margin-bottom: 15px;
	}
	.left-column {
		width: 18px;
	}
	.form-group {
		margin-bottom: 0px;
	}
	.help-block {
		position: absolute;
		width: 300px;
		margin-top: 0;
	}
}

.table-incisions {
	@extend .text-navy;
	th,
	td {
		border-color: $navy !important;
		vertical-align: middle !important;
		border-top: 0 !important;
		@extend .font-regular-italic;
	}
	td:last-child {
		text-align: right;
	}

	.remove-med {
		padding: 0;
		margin: 0;
		border: 1px solid $navy;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: white;

		&:hover {
			background-color: $navy;
			color: white;
		}
	}
}

.btn-add-incision {
	height: 34px;
	width: 34px;
	margin-left: 0.5em;
	padding: 0;
}

.flex-allergy {
	display: flex;
	align-items: center;

	.grow {
		flex: 1 0;
		margin-right: 20px;
	}
}

.anaesthetic-wrap {
	display: flex;
	align-items: center;

	.form-group {
		width: 260px;
		margin-right: 20px;
	}

	.help-block {
		position: absolute;
		margin-top: 0;
	}
}

.text-area-wrap {
	position: relative;

	.count-remaining {
		position: absolute;
		font-size: 10px;
		bottom: 0;
		right: 15px;
		color: $navy;
		@extend .font-regular-italic;
	}
}

.diabetes-pop-up-wrap {
	width: 50%;
	position: relative;
}

.retinopathy-pop-up-OD {
	.retinopathy-popup {
		left: calc(100% + 10px);
	}
}

.retinopathy-pop-up-OS {
	.retinopathy-popup {
		right: calc(100% + 10px);
	}
}

.showRetinopathy {
	.retinopathy-popup {
		display: block;
	}
}

.maculopathy-pop-up-OD {
	.maculopathy-popup {
		left: calc(100% + 10px);
	}
}

.maculopathy-pop-up-OS {
	.maculopathy-popup {
		right: calc(100% + 10px);
	}
}

.showMaculopathy {
	.maculopathy-popup {
		display: block;
	}
}

.retinopathy-popup,
.maculopathy-popup {
	border: 1px solid $navy;
	padding: 15px;
	font-size: 10px;
	color: $navy;
	background-color: white;
	width: 480px;
	position: absolute;
	z-index: 1;
	top: 0;
	display: none;

	.table-bordered {
		border: none !important;
		margin-bottom: 10px;

		th {
			@extend .font-bold-italic;
		}

		th,
		td {
			border-right: 1px solid $navy;
			border-left: 1px solid $navy;

			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}
		}

		tr:last-child {
			td {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}

	td,
	p {
		@extend .font-regular-italic;
	}

	td,
	th {
		border-color: $navy !important;
	}

	p {
		margin-bottom: 0;
	}
}

.retinopathy-popup {
	th:first-child,
	td:first-child {
		width: 120px;
	}
}

.operation-form {
	.flex-lens {
		display: flex;

		.form-group {
			width: 150px;
			margin-right: 20px;
		}

		.form-group-axis {
			margin-right: 0;
			width: 110px;
		}
	}
}

.allergy-form-wrap {
	.help-block {
		position: absolute;
	}
}

.locsiii-wrap {
	position: relative;
	width: 50%;
}

.locsiii-img-wrap {
	position: absolute;
	width: 500px;
	img {
		width: 100%;
	}
}

.locsiii-img-wrap-OD {
	position: absolute;
	width: 700px;
	left: calc(100% + 15px);
	top: 0;
	z-index: 1;
	display: none;

	img {
		width: 100%;
	}
}

.locsiii-img-wrap-OS {
	position: absolute;
	width: 500px;
	right: calc(100% + 15px);
	top: 0;
	z-index: 1;
	display: none;

	img {
		width: 100%;
	}
}

.showOpal,
.showColour,
.showCortical,
.showPupil,
.showSub {
	display: block !important;
}

.operation-note-header {
	// outline: 1px dotted pink;
	margin-bottom: 20px;

	.head-flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		&:last-child {
			margin-top: 10px;
		}
	}

	h3,
	h4 {
		margin: 0;
		@extend .font-bold;
		@extend .text-navy;
	}

	h6 {
		margin: 0;
		padding-left: 1.5em;
		font-size: 16px;
		@extend .text-navy;
	}

	.head-link {
		color: $navy;
		cursor: pointer;
		font-size: 14px;
		text-decoration: underline;
		@extend .font-regular;
	}

	.head-name {
		margin-right: 20px;
	}

	.header-left,
	.header-right {
		// outline: 1px solid green;
		display: flex;
		align-items: flex-end;
	}

	.header-right {
		// margin-right: 80px;
		// outline: 1px solid blue;
	}
}

.popover-preop-wrap {
	padding: 20px;
	padding-bottom: 0;
	width: 300px;
	text-align: center;

	label {
		color: $navy !important;
		@extend .font-regular;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.form-control {
		border-radius: 20px;
		box-shadow: none;
		@extend .font-regular;
		color: $navy;
		font-size: 12px;
		border: 1px solid $navy;
	}

	.form-group:last-child {
		margin-bottom: 10px;
	}
}

.popover {
	border-color: $navy;

	.marker {
		position: relative;
		top: 2px;
	}
}

.popover.bottom > .arrow {
	border-bottom-color: $navy;
}

.spinner-wrap {
	position: relative;
}

.vertical-buttons {
	display: flex;
	flex-direction: column;

	button + button {
		margin-top: 10px;
	}
}

.my-modal-footer-patient-details {
	.btn-navy {
		width: 220px;
	}
}

.incisions-image {
	width: 100%;
	padding-left: 5em;
	padding-right: 5em;
	margin-top: 1em;
	margin-bottom: 2em;
}

.margin-top-2em {
	margin-top: 2em;
}

.margin-top-1em {
	margin-top: 1em;
}

.padding-top-dot-4em {
	padding-top: 0.4em;
}

.custom-slider.rzslider :focus {outline:none;}

.custom-slider.rzslider {
	margin-top: 1em;
	margin-bottom: 1em;
}

.custom-slider.rzslider .rz-bar {
	height: 8px;
	border-radius: 4px;
  }

  .custom-slider.rzslider .rz-pointer {
	width: 16px;
	height: 16px;
	top: auto; /* to remove the default positioning */
	bottom: 0;
	margin-bottom: -6px;
  }
  
  .custom-slider.rzslider .rz-pointer:after {
	display: none;
  }
  
  .custom-slider.rzslider .rz-bubble {
	bottom: 14px;
  }
  
  .custom-slider.rzslider .rz-limit {
	font-weight: bold;
  }

  .custom-slider.rzslider .rz-tick-value {
	top:0px;
	color: transparent;
  }
  
  .custom-slider.rzslider .rz-tick {
	width: 1px;
	height: 10px;
	margin-left: 8px;
	border-radius: 0;
	top: -1px;
  }

  .transparent-color {
	  color:transparent !important;
  }

  .btn-white {
	background-color: $white;
	color: $navy;
	width: 200px;
	border-color: $navy;
}

.margin-middle {
	margin-left: 1em;
	margin-right: 1em;
}

.head-link {
	color: $navy;
	cursor: pointer;
	font-size: 14px;
	text-decoration: underline;
	@extend .font-regular;
}

.font-size-16 {
	font-size: 16px;
}

.postoperative-header {
	width: 100%;
	text-align: center;
	margin-top: 2em;
	margin-bottom: 2em;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 0px dashed;
	border-top: 0px solid \9;
	border-right: 0px solid transparent;
	border-left: 0px solid transparent;
  }

.font-regular {
	font-family: 'Gordita Regular' !important;
}

.font-size-8 {
	font-size: 8px !important;
}
.margin-to-right {
	margin-left: 50%;
}

.grid-column-no-padding {
	padding-left: 0px;
	padding-right: 0px;

}
.select-no-margin {
	margin-right: 0px !important;
	width: 100% !important;
}
