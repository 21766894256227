button.btn {
	border-radius: 24px;
}

.btn-dropdown-multiselect {
	height: 34px;
	text-align: start;
	border-color: $navy;
	background-color: $white;
	color: $navy;
	padding: 4px 18px;
	font-size: 12px;
	width: 100%;
	min-width: 16em;
	transition: all 0.3s ease;

	&:hover,
	&:active {
		color: $white;
		background-color: color($navy l(35%));
		outline: 0;
	}
	&:focus {
		color: $navy;
		outline: 0;
	}
	&:hover:focus {
		color: $white;
	}
}

.btn-navy {
	background-color: $navy;
	color: $white;
	padding: 4px 18px;
	font-size: 12px;
	width: 100%;
	max-width: 220px;
	transition: all 0.3s ease;

	&:hover,
	&:active {
		color: $white;
		background-color: color($navy l(35%));
		outline: 0;
	}
	&:focus {
		color: $white;
		outline: 0;
	}
}

.btn-cancel {
	background-color: transparent;
	color: $textGrey;
	padding: 4px 18px;
	font-size: 12px;
	border: 1px solid $cancelBorder;
	width: 100%;
	max-width: 220px;
	transition: all 0.3s ease;

	&:hover,
	&:active {
		color: $logoutGrey;
		background-color: color($white l(90%));
		outline: 0;
	}
	&:focus {
		outline: 0;
	}
}

.btn-cyan {
	background-color: $white;
	color: $cyan;
	border: 1px solid $cyan;
	padding: 4px 18px;
	font-size: 12px;
	width: 100%;
	max-width: 220px;
	transition: background-color 0.3s ease;

	&:hover,
	&:active {
		color: $cyan;
		background-color: color($white l(-8%));
	}
	&:focus {
		color: $cyan;
		outline: 0;
	}
}

.btn-cyan-solid {
	background-color: $cyan;
	color: $white;
	border: 1px solid $cyan;
	padding: 4px 18px;
	font-size: 12px;
	width: 100%;
	max-width: 220px;
	transition: background-color 0.3s ease;

	&:hover,
	&:active {
		color: $white;
		background-color: color($cyan l(-5%));
	}
	&:focus {
		color: $white;
		outline: 0;
	}
}

.btn-morph-wrap {
	position: relative;
	overflow: visible;

	.btn-morph {
		width: 270px;
		max-width: 270px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.morph-result {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 280px;
		width: 120px;
	}
}

#download-referral-button {
	width: 240px;
}
