.search-wrap {
}

.search-form {
	padding-top: 10px;
	/* padding-bottom: 10px; */
	.form-group {
		position: relative;

		.form-control {
			font-size: 12px !important;
			border-radius: 0;
			box-shadow: none;
			border-radius: 20px;
			@extend .font-regular;
			color: $navy;
			border: 1px solid $navy;
			&:focus {
				box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
			}
		}
		label {
			margin-right: 10px;
		}
	}

	.form-group-lastName {
		input {
			width: 250px;
		}
	}

	.form-group-DOB {
		/* margin-left: 50px; */
		.form-control {
			width: 80px;

			&:nth-child(3) {
				margin: 0 3px;
			}
		}
		/* margin-bottom: 1rem; */
	}
	.form-group-buttons {
		/*position: relative;*/
		display: flex;
		flex-direction: column;
		float: right;
		margin-right: 0;
		position: relative;
		top: -10px;
		button:first-child {
			margin-bottom: 10px;
		}
	}
	.form-group-search-buttons {
		display: flex;
		justify-content: center;
		gap: 4rem;
	}
}

.search-results {
	table {
		margin-bottom: 0;
		tr {
			&:first-child {
				background-color: $white !important;
			}

			th {
			}
		}
	}
}

.search-results-v2 {
	table {
		margin-bottom: 0;
		tr {
			&:first-child {
			}

			th {
			}
		}
	}
}

.table-striped > tbody > tr:nth-of-type(even) {
	/* background-color: $paleBlue; */
	background-color: $white;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: $white;
}

.table-blue-striped > tbody > tr:nth-of-type(even) {
	background-color: $paleBlue;
}

.table-blue-striped > tbody > tr:nth-of-type(odd) {
	background-color: $white;
}

.table-hover > tbody > tr:hover {
	background-color: $navy;

	td {
		color: $white;
	}
}

.table-no-border {
	border-width: 0px;
	border-top-width: 0.1em;
	border-top-color: $navy;
	th {
		color: $navy;
		font-family: 'Gordita Heavy';
	}
}

.table-no-border > tbody > tr[disabled] td {
	filter: blur(1px);
	pointer-events: none;
  }

.filter-dropdown {
	color: $navy;
    font-size: 12px;
}

.filter-select {
	border-radius: 20px;
	box-shadow: none;
	@extend .font-regular;
	// color: $textGrey;
	color: $navy;
	font-size: 12px;
	border: 1px solid $navy;
}

.search-label {
	color: $navy !important;
}

.or-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: -1rem;
	width: 100%;
}

.or-seperator {
	width: 100%;
	margin-top: -5px;
	margin-bottom: 0px;
	border-color: $navy;
}

.address-icon {
	width: 2rem; 
	height: 2rem;
	margin-top: -0.5rem;
}

.score_text {
	color: $navy;
	font-family: 'Gordita Light Italic';
}

.flex-column-layout {
	display: flex !important;
	flex-direction: column;
	gap: 0.5rem;
}

.lastname-input {
	width: 250px !important;
	margin-right: 1rem;
}

.flex-row-layout {
	display: flex !important;
	flex-direction: row;
	gap: 0.5rem;
	align-items: baseline;
}

.hyper-link {
	color: $navy;
	text-decoration: underline;
	cursor: pointer;
	margin-left: 1rem;
}

.highlight-row {
	background-color: $navy;
	color: white;
	cursor: pointer !important;
}

.normal-row {
	background-color: white;
	color: $navy;
	cursor: pointer !important;
}