.op-header {
	color: $white;
	text-align: center;
	padding: 5px 0;
	margin-bottom: 10px;

	h4 {
		font-size: 1em;
	}
}

.navy-text {
	color: $navy !important;
}

.navy-line {
	height: 2px;
	background-color: $navy;
	margin-left: 1.5em;
	margin-right: 1.5em;
	margin-top: 1em;
}

.pre-op-form {
	@extend .font-regular;
	color: $textGrey;
	font-size: 12px;
	// background-color: pink;
	padding: 0 20px;

	select,
	.form-control {
		border-radius: 20px;
		box-shadow: none;
		@extend .font-regular;
		// color: $textGrey;
		color: $navy;
		font-size: 12px;
		border: 1px solid $navy;
	}

	.form-group-label {
		padding-right: 20px;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 25px;

		.control-label {
			text-align: right;
		}
	}

	.flex {
		display: flex;
		padding: 0;
		position: relative;

		select,
		.input,
		.inline-number,
		.mark-wrap {
			margin-right: 20px;
			width: 100px;
		}
	}

	.mark-wrap,
	.mark-wrap-deg {
		position: relative;

		.six-pre {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -20px;
		}

		.stripe-post {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -15px;
		}

		.stripe-pre {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -12px;
		}
	}

	.mark-wrap-deg {
		.deg {
			width: 80px;
			padding-left: 6px;
			padding-right: 6px;
		}

		.stripe-post {
			right: -37px;
		}
	}

	label {
		color: $navy !important;
	}

	.marker {
		position: relative;
		top: 2px;
	}
}

.btn-next {
	width: 120px;
	@extend .font-bold;
}

.check-label {
	position: relative;

	.check-span {
		position: relative;
		top: 2px;
	}
}

.lens {
	width: 220px;
}

.lens-label {
	margin-top: 34px;
}

.visual-label {
	margin-top: 70px;
}

.form-group-label-top {
	margin-top: 40px;
}

.col-bin {
	padding-left: 60px;
}

.pressure-wrap {
	display: flex;
	justify-content: space-between;
	max-width: 460px;

	.form-group {
		width: 47%;
	}
}

.half-width {
	max-width: 50%;
}

.quarter-width {
	max-width: 25%;
}

.blue-box {
	border: 1px solid $navy;
	padding: 15px;
	background-color: $paleBlue;
	margin-bottom: 15px;
	border-radius: 8px;
	.header {
		@extend .text-navy;

		.h4 {
			@extend .font-bold;
			margin-bottom: 5px;
			margin-top: 0;
		}
	}
}

.eye-heading {
	margin-bottom: 20px;
}

.pre-op-save-wrap {
	min-height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0;

	.progression-wrap {
		min-width: 250px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.save-progress {
			cursor: pointer;
			margin-right: 20px;
			@extend .text-navy;
		}
	}

	.progression-alert-wrap {
		width: calc(100% - 280px);

		.alert {
			margin-bottom: 0;

			& + .alert {
				margin-top: 10px;
			}
		}
	}
}

.stack-vert {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	top: 2px;
	margin-left: 10px;

	.radio-inline + .radio-inline {
		margin-left: 0;
		margin-top: 5px;
	}
}

.optional {
	@extend .text-purple;
	@extend .font-light-italic;
}

.flex-biometry {
	align-items: center;

	.control-label {
		margin-right: 15px;
		margin-bottom: 0;
	}
}

.biometry-input {
	width: 100px;
	margin-right: 20px;
}

.axis-wrap {
	position: relative;
}

.axis-times,
.axis-deg {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform: translateY(-50%);
}

.axis-times {
	right: calc(100% + 6px);
}

.axis-deg {
	left: 110px;
}

.section-label {
	margin-bottom: 10px;
}

.biometry {
	.form-row {
		margin-bottom: 15px;
	}
	.left-column {
		width: 28px;
	}
	.form-group {
		margin-bottom: 0px;
	}
	.help-block {
		position: absolute;
		width: 300px;
		margin-top: 0;
	}
}

.bio-internal-flex {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.table-medication {
	@extend .text-navy;
	th,
	td {
		border-color: $navy !important;
		vertical-align: middle !important;
	}
	td:last-child {
		text-align: right;
	}

	.remove-med {
		padding: 0;
		margin: 0;
		border: 1px solid $navy;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: white;

		&:hover {
			background-color: $navy;
			color: white;
		}
	}
}

.btn-add-allergy {
	height: 34px;
	width: 34px;
	margin: 0;
	padding: 0;
}

.flex-allergy {
	display: flex;
	align-items: center;

	.grow {
		flex: 1 0;
		margin-right: 20px;
	}
}

.anaesthetic-wrap {
	display: flex;
	align-items: center;

	.form-group {
		width: 260px;
		margin-right: 20px;
	}

	.help-block {
		position: absolute;
		margin-top: 0;
	}
}

.text-area-wrap {
	position: relative;

	.count-remaining {
		position: absolute;
		font-size: 10px;
		bottom: 0;
		right: 15px;
		color: $navy;
		@extend .font-regular-italic;
	}
}

.diabetes-pop-up-wrap {
	width: 50%;
	position: relative;
}

.retinopathy-pop-up-OD {
	.retinopathy-popup {
		left: calc(100% + 10px);
	}
}

.retinopathy-pop-up-OS {
	.retinopathy-popup {
		right: calc(100% + 10px);
	}
}

.showRetinopathy {
	.retinopathy-popup {
		display: block;
	}
}

.maculopathy-pop-up-OD {
	.maculopathy-popup {
		left: calc(100% + 10px);
	}
}

.maculopathy-pop-up-OS {
	.maculopathy-popup {
		right: calc(100% + 10px);
	}
}

.showMaculopathy {
	.maculopathy-popup {
		display: block;
	}
}

.retinopathy-popup,
.maculopathy-popup {
	border: 1px solid $navy;
	padding: 15px;
	font-size: 10px;
	color: $navy;
	background-color: white;
	width: 480px;
	position: absolute;
	z-index: 1;
	top: 0;
	display: none;

	.table-bordered {
		border: none !important;
		margin-bottom: 10px;

		th {
			@extend .font-bold-italic;
		}

		th,
		td {
			border-right: 1px solid $navy;
			border-left: 1px solid $navy;

			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}
		}

		tr:last-child {
			td {
				border-bottom: none;
				padding-bottom: 0;
			}
		}
	}

	td,
	p {
		@extend .font-regular-italic;
	}

	td,
	th {
		border-color: $navy !important;
	}

	p {
		margin-bottom: 0;
	}
}

.retinopathy-popup {
	th:first-child,
	td:first-child {
		width: 120px;
	}
}

.pre-op-form {
	.flex-lens {
		display: flex;

		.form-group {
			width: 150px;
			margin-right: 20px;
		}

		.form-group-axis {
			margin-right: 0;
			width: 110px;
		}
	}
}

.allergy-form-wrap {
	.help-block {
		position: absolute;
	}
}

.locsiii-wrap {
	position: relative;
	width: 50%;
}

.locsiii-img-wrap {
	position: absolute;
	width: 500px;
	img {
		width: 100%;
	}
}

.locsiii-img-wrap-OD {
	position: absolute;
	width: 700px;
	left: calc(100% + 15px);
	top: 0;
	z-index: 1;
	display: none;

	img {
		width: 100%;
	}
}

.locsiii-img-wrap-OS {
	position: absolute;
	width: 500px;
	right: calc(100% + 15px);
	top: 0;
	z-index: 1;
	display: none;

	img {
		width: 100%;
	}
}

.showOpal,
.showColour,
.showCortical,
.showPupil,
.showSub {
	display: block !important;
}

.pre-op-header {
	// outline: 1px dotted pink;
	margin-bottom: 20px;

	.head-flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		&:last-child {
			margin-top: 10px;
		}
	}

	h3,
	h4 {
		margin: 0;
		@extend .font-bold;
		@extend .text-navy;
	}

	.head-link {
		color: $navy;
		cursor: pointer;
		font-size: 14px;
		text-decoration: underline;
		@extend .font-regular;
	}

	.head-name {
		margin-right: 20px;
	}

	.header-left,
	.header-right {
		// outline: 1px solid green;
		display: flex;
		align-items: flex-end;
	}

	.header-right {
		// margin-right: 80px;
		// outline: 1px solid blue;
	}
}

.graphs-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
} 

.card {
    background-color: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    box-sizing: border-box;
	margin: 1em;
	margin-right: 0;
}
  
.card:hover {
	/* box-shadow: 0 8px 28px 0 rgba(0,0,0,0.2); */
  }
  
.card-container {
	padding-left: 1em;
	padding-right: 1em;
	/* padding: 2px 16px; */
}

.patients-empty {
	@extend .card;
	background-color: #f4f7fe; 
	margin: 0;
	height: 'auto';
	h4 {
		@extend .font-bold;
		@extend .text-navy;
		padding: 2em;
	}
}

.menu-section {
	@extend .card;
	background-color: #f4f7fe; 
	min-height: 10em;
	margin-left: 0;
	margin-top: 0;
	h4 {
		text-align: left;
		@extend .font-bold;
		@extend .text-navy;
		padding-left: 1em;
		padding-top: 1em;
		padding-bottom: 0.4em;
	}
}

.menu-container {
}

.menu-list {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}

.menu-arrow {
	border-width: 11px;
	/* position: absolute; */
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	margin-top: -11px;
	margin-left: -11px;
	border-top-width: 0;
	border-bottom-color: #f4f7fe;
	margin-left: 48%;
	/* left: 50%; */
}

.vertical-bar {
	width: 4px;
	background-color: $navy;
	align-self: stretch;
	margin-left: 2em;
	margin-top: 1em;
	margin-bottom: 1em;
}

.menu-block {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.right-arrow {
	margin-top: 1em;
	width: 16px;
	height: 16px;
}

.menu-item {
	width: 11em;
	h4 {
		margin: 0;
		@extend .font-bold;
		@extend .text-navy;
		font-size: 12px;
	}
	p {
	  padding-left: 1em;
	  padding-right: 0.2em;
	  text-align: left;
	  font-size: 12px;
	}
}

.pre-op-header-v2 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.header-title {
		flex: 1;
		position: absolute;
		top: 10rem;
	}

	.menu-container {
		flex: 12;
	}

	.link-disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.6;
	}

	.arrow-down {
		border: solid $navy;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 3px;
		margin-bottom: 2px;
		margin-left: 2px;
		transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
	}

	.arrow-right {
		border: solid $navy;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 3px;
		margin-bottom: 2px;
		margin-left: 2px;
		transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
	}

	h3,
	h4 {
		margin: 0;
		@extend .font-bold;
		@extend .text-navy;
	}

	h5 {
		margin: 0;
		font-size: 16px;
		@extend .font-bold;
		@extend .text-navy;
	}

	.patient-card {
		@extend .card;
		padding-bottom: 1em;
	}

	.patient-detail-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-top: 0.5em;
		h4 {
			font-size: 14px;
		}
	}

	.vertical-section {
		display: flex;
		flex-direction: column;
	}

}


.popover-preop-wrap {
	padding: 20px;
	padding-bottom: 0;
	width: 300px;
	text-align: center;

	label {
		color: $navy !important;
		@extend .font-regular;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.form-control {
		border-radius: 20px;
		box-shadow: none;
		@extend .font-regular;
		color: $navy;
		font-size: 12px;
		border: 1px solid $navy;
	}

	.form-group:last-child {
		margin-bottom: 10px;
	}
}

.popover {
	border-color: $navy;
    /* width: 20em;
	max-width: 20em; */
	.marker {
		position: relative;
		top: 2px;
	}
}

.popover.bottom > .arrow {
	border-bottom-color: $navy;
}

.spinner-wrap {
	position: relative;
}

.vertical-buttons {
	display: flex;
	flex-direction: column;

	button + button {
		margin-top: 10px;
	}
}

.vertical-radio-groups {
	display: flex;
	flex-direction: column;
	margin-top: 1em;
}

.radio-button-inline {
	margin-left: 0px !important;
	margin-bottom: 1em;
}

.my-modal-footer-patient-details {
	.btn-navy {
		width: 220px;
	}
}

.width_35_em{
	width: 35em;
}

hr.life-quality-line-break {
	border-top-color: #3850a2;
	margin-top: 0;
	margin-bottom: 0;
}

.customer_checkbox_red {
   input[type=checkbox]:before {
		content: "";
		display: block;
		position: absolute;
		width: 13px;
		height: 13px;
		border: solid red;
		border-radius: 2px;
		background-color: transparent;
}
input[type=checkbox]:checked:before {
		content: "";
		display: block;
		position: absolute;
		width: 15px;
		height: 15px;
		background-color: red;
}
   input[type=checkbox]:checked:after {
		content: "";
		display: block;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 5px;
		left: 5px;
}
 span {
	position: relative;
    top: -1px;
	padding-left: 0.5em;
 }
}

.customer_checkbox_green {
	input[type=checkbox]:before {
		 content: "";
		 display: block;
		 position: absolute;
		 width: 13px;
		 height: 13px;
		 border: solid green;
		 border-radius: 2px;
		 background-color: transparent;
 }
 input[type=checkbox]:checked:before {
		 content: "";
		 display: block;
		 position: absolute;
		 width: 15px;
		 height: 15px;
		 background-color: green;
 }
	input[type=checkbox]:checked:after {
		 content: "";
		 display: block;
		 width: 5px;
		 height: 10px;
		 border: solid white;
		 border-width: 0 2px 2px 0;
		 -webkit-transform: rotate(45deg);
		 -ms-transform: rotate(45deg);
		 transform: rotate(45deg);
		 position: absolute;
		 top: 5px;
		 left: 5px;
 }
  span {
	 position: relative;
	 top: -1px;
	 padding-left: 0.5em;
  }
 }


.customer_radio_red {
	.marker {
		margin-left: 0.5em;
		margin-right: 1em;
		font-size: 1.6em;
		color: red !important;
	}
	input[type="radio"]:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
    };

    input[type="radio"]:checked:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: red;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
    };
}

.customer_radio_green {
	.marker {
		margin-left: 0.5em;
		margin-right: 1em;
		font-size: 1.6em;
		color: green !important;
	}
	input[type="radio"]:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
    };

    input[type="radio"]:checked:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: green;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
    };
}

.customer_radio_normal {
	.marker {
		margin-left: 0.5em;
		margin-right: 1em;
		font-size: 1.6em;
	}
	input[type="radio"]:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
    };

    input[type="radio"]:checked:after {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $navy;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 0px solid white;
    };
}

.pagination {
	margin-top: 2em !important;
}

.pagination>li>a,
.pagination>li>span {
  border: 0px;
  padding: 3px 6px;
}

.pagination>li.active>a {
	background: transparent;
	color: #337ab7;
	font-weight: bold;
	font-size: 16px;
	margin-top: -0.2em;
  }
/* .pagination>li.active>a {
  background: purple;
  color: #fff;
} */

/* .pagination {
	display: inline-block;
	padding-left: 0;
	.li {
		background-color: red;
	}
} */

.popover_ul {
	margin-left: -2em;
}

.popover_container {
	.popover {
		width: 40em;
		max-width: 40em;
	}
}

.popover_label {
	margin-right: 0px !important;
	font-family: 'Gordita Bold';
}

.pink_header {
	padding-left: 2%;
	padding-right: 2%;
	flex-direction: column !important;
	background-color: #EEE6FF;
}

.purple_header {
	padding-left: 2%;
	padding-right: 2%;
	flex-direction: column !important;
	background-color: rgb(217,210,233);
}

.blue_header {
	padding-left: 2%;
	padding-right: 2%;
	flex-direction: column !important;
	background-color: rgb(207,226,243);
}

.long_section{
	width: 30% !important;
	margin-left: -5%;
	margin-right:15%;
}
