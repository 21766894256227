
section.login {
	height: 100vh;
	background-image: url('../img/segment.png'), url('../img/login_bg_1920.jpg');
	background-repeat: no-repeat, no-repeat;
	background-position: 0 0, 50% 50%;
	background-size: auto 80vh, cover;	
}

.login-logo-flex {
	position: absolute;
	width: 100%;
	top: 5vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 80px;

	.login-logo-wrap {
		height: 18vh;
		img {
			height: 100%;
		}
	}
	.redefining {
		text-align: right;
		font-size: 22px;
	}
}


.login-box-wrap-flex {
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	h5.h5 {
		font-size: 12px;
	}
}

.login-box {
	padding: 0px 150px 0px 80px;
	border-top-right-radius: 160px 160px; 
	border-bottom-right-radius: 160px 160px;
}

.login-title {
	margin: 15px 0;
}