.register-edit-form {
	.col-sm-3 {
		padding-right: 0;
		padding-left: 0;
	}
	label.control-label {
		padding-top: 8px;
	}
	.form-control {
		font-size:12px !important;
		border-radius: 0;
		box-shadow: none;
	}
	.dob-group {
		display: flex !important;
		flex-direction: row;
		justify-content: space-between;

		.form-control {
			width: 31%;
			margin: 0;
		}
		span.help-block {
			position: absolute;
			top: 100%;
			margin-top: 0;
		}


		.form-control:nth-child(2) {
			/*margin: 0 15px;*/
		}
	}
	.radio-inline {
		width: 80px;
		/*text-align: center;*/
		input {
			position: relative;
			/*right: 10px;*/
		}
		.marker {
			position: relative;
			left: 7px;
			top: -1px;
		}
	}
	.ethnicity-group {

	}
}

.register-edit-form-v2 {
	.col-sm-3 {
		padding-right: 0;
		padding-left: 0;
	}
	label.control-label {
		padding-top: 8px;
	}
	.form-control {
		font-size:12px !important;
		border-radius: 0;
		box-shadow: none;
	}
	.dob-group {
		display: flex !important;
		/* flex-direction: row; */
		justify-content: space-between;

		.form-control {
			width: 31%;
			margin: 0;
		}
		span.help-block {
			position: absolute;
			margin-top: 3em;
		}
		.form-control:nth-child(2) {
			/*margin: 0 15px;*/
		}
	}
	.radio-inline {
		width: 80px;
		/*text-align: center;*/
		input {
			position: relative;
			/*right: 10px;*/
		}
		.marker {
			position: relative;
			left: 7px;
			top: -1px;
		}
	}
	.ethnicity-group {

	}
}

.animate-show-hide.ng-hide {
	opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
	transition: all ease 0.3s;
}

.animate-me {
	opacity: 1;
}

.checkbox-inline {
	min-width: 25%
}

.form-group-ethnicity {
	margin-bottom: 9px;

	label.checkbox-inline:first-child {
		margin-left: 10px;
	}
	label.checkbox-inline {
		span.marker {
			position: relative;
			bottom:-2px;
		}
	}

	.checkbox-inline-other {
		position: relative;
		left: -4px;
	}

	.col-sm-9 {
		position: relative;
	}

	span.help-block {
		position: absolute;
		top:100%;
	}
}

/*Google Places API to bring autocomplete higher z-index than bootstrap modal*/
.pac-container {z-index: 10000 !important;}

.form-impact {
	.form-group {
		margin-bottom: 25px;
	}

	label.control-label {
		text-align: left !important;
		@extend .font-regular;
		@extend .text-textGrey;

		.question-mark {
			position: relative;
			display: inline-block;
			height: 20px;
			bottom:2px;
			margin-right: 10px;
			img {
				height: 100%;
				overflow:hidden;
			}
		}
	}
	.radio-flex-wrap {
		display: flex;
		justify-content: space-between;

		.radio-inline {
			width: 80px;
			text-align: center;

			.marker {
				position: relative;
				top:2px;
				@extend .font-bold;
			}
		}
	}
	
	.control-label-left {padding-right: 0;}

	.form-group-header {
		.radio-flex-wrap {

			.radio-inline {
				text-align: center;
				padding-left: 0;
				padding-right: 0;
				width: 80px;
				@extend .text-textGrey;
				@extend .font-medium;
			}
		}
	}
	
}
.life-quality {
	.form-group.has-error {
		outline: 1px solid $danger;
		padding-top: 16px;
		padding-bottom: 16px;
		.radio-flex-wrap {
			/*padding-bottom: 6px;*/
			margin-bottom: 10px;
			position: relative;

			.help-block {
				position: absolute;
				top: 240%;
				/* bottom: -45px; */
				left: 80%;
				/* width: 100%;
				text-align: center; */
			}

		}
	}
}
.form-footer {
	display: flex;
	justify-content: flex-end;
	.submit-wrap-flex {
		padding: 20px 30px 20px 50px;
		border-radius: 50px;
		background: $white;
		/*background: linear-gradient(270deg, $purple, $navy) !important;*/
		display: flex;

		.note-wrap {
			width: 400px;
			margin-right: 20px;
			p {
				margin: 0;
				&:first-child {margin-bottom: 10px;}
			}
		}
		.button-wrap {
			width: 200px;
			align-self: center;
		}
	}

	.showing-warning {
		background: linear-gradient(270deg, $purple, $navy) !important;
	}
}

.form-login {
	width: 280px;
	padding-top: 15px;
	
	.form-group {
		margin-bottom: 30px;

		input {
			border-radius: 24px;
		}
		&:last-child {
			margin-bottom: 15px;
		}
	}
	button {font-size: 12px;}

	.btn-login {
		float: left;
		width: 34%;
		padding: 6px 18px;
	}
	.btn-lost {
		color: $textGrey;
		float: right;
		width: 60%;
		transition: background-color .1s linear;

		&:hover,
		&:active {
			color: $textGrey;
			background-color: $white;
		}
	}
}


form[name=search] {
	span.help-block {
		text-align: center;
		position: absolute;
		width: 100%;
	}
}

.has-success .form-control {
	border-color: $cyan;
	&:focus {
		border-color: $cyan;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 4px $cyan;
	}
}

.has-error .form-control {
	border-color: $danger;
	&:focus {
		border-color: $danger;
	}
}

form[name=loginForm] {
	.help-block {
		text-align: left;
		position: absolute;
		color: $white;
		margin-bottom: 0;
		padding-left: 13px;
	}
}

label {
	color: $textGrey !important;
}

form[name=setPassword] {
	.form-control {
		border-radius: 0;
	}
	.help-block {
		position: absolute;
	}
}

form[name=formImpact] {
	.form-group.has-error {
		outline: 1px solid $danger;
		padding-top: 2px;
		.radio-flex-wrap {
			/*padding-bottom: 6px;*/
			margin-bottom: 10px;
			position: relative;

			.help-block {
				position: absolute;
				/*top: 100%;*/
				bottom: -40px;
				width: 100%;
				text-align: center;
			}

		}
	}
}

form[name=exam] {
	span.help-block {
		position: absolute;
		white-space: nowrap;
		margin-top: 0;
	}
}

.btn-switch {
    position: relative;
    display: block;
    width: 50px;
    height: 28px;
    cursor: pointer;
    background-color: #F27878;
    border: 2px solid #F27878;
    border-radius: 40px;
    
    .btn-switch-circle {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border-radius: 40px;
    }
}

.btn-switch--on {
    background-color: #80CDBE;
    border: 2px solid #80CDBE;
    
    .btn-switch-circle--on {
        left: auto;
        right: 0;
    }
}

.surgery-agreement-form-footer {
	display: flex;
	justify-content: flex-end;
	.submit-wrap-flex {
		padding: 12px 15px 12px 25px;
		border-radius: 50px;
		background: $white;
		/*background: linear-gradient(270deg, $purple, $navy) !important;*/
		display: flex;

		.note-wrap {
			width: 400px;
			margin-right: 20px;
			p {
				margin: 0;
				&:first-child {margin-bottom: 10px;}
			}
		}
		.button-wrap {
			/* width: 200px; */
			align-self: center;
		}
	}

	.showing-warning {
		background: linear-gradient(270deg, $purple, $navy) !important;
	}
}